import React from "react"
import Img from "gatsby-image"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"
import SingleClassBooksList from "../components/single-class-books-list"

const seoTitle = "Books List"

const BooksListPage = ({data}) => {
    return <Layout>
        <SEO title={seoTitle}/>
        <div className="container all-classes-books-list">
            <div className="row article-banner">
                <div className="col">
                    <Img fluid={data.bannerql.childImageSharp.fluid} />
                </div>
            </div>
            <div className="row article-heading">
                <div className="col">
                    <h3><span className="label label-primary">{data.markupql.edges[0].node.frontmatter.heading}</span></h3>
                </div>
            </div>
            <div className="row article-text">
                {/* <a href="#nursery">Nursery</a>
                <a href="#lkg">LKG</a>
                <a href="#ukg">UKG</a> */}
                <a href="#i">I</a>
                <a href="#ii">II</a>
                <a href="#iii">III</a>
                <a href="#iv">IV</a>
                <a href="#v">V</a>
                <a href="#vi">VI</a>
                <a href="#vii">VII</a>
                <a href="#viii">VIII</a> 
                <a href="#ix">IX</a>           
                <a href="#x">X</a>      
                {/* <SingleClassBooksList name="Nursery" /> 
                <SingleClassBooksList name="LKG" />
                <SingleClassBooksList name="UKG" />  */}
                <SingleClassBooksList name="I" /> 
                <SingleClassBooksList name="II" />   
                <SingleClassBooksList name="III" />  
                <SingleClassBooksList name="IV" />
                <SingleClassBooksList name="V" />  
                <SingleClassBooksList name="VI" />
                <SingleClassBooksList name="VII" />
                <SingleClassBooksList name="VIII" /> 
                <SingleClassBooksList name="IX" />
                <SingleClassBooksList name="X" />
            </div>
        </div>
    </Layout>
}
export default BooksListPage

export const query1 = graphql `
    query {
    bannerql: file(relativePath: {eq: "banners/list-of-books-1.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1900) {
                ...GatsbyImageSharpFluid
            }
        }
    }

    markupql: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/books-list-article/i"}}) {
        edges {
          node {
            frontmatter {
              heading
            }
          }
        }
    }
}
`