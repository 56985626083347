import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {FaFile} from "react-icons/fa"

const SingleClassBooksList = ({name, qlBooksList, qlFileDownload}) => {
    const data = useStaticQuery(graphql`
    query {
      
      iconql: file(relativePath: {eq: "icons/word.png"}) {
        childImageSharp {
            fixed(width: 16, height: 16) {
                ...GatsbyImageSharpFixed
            }
        }
      }

      nurseryClassBooksList: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/books-list-class-nursery.md/i"}}) {
          edges {
            node {
              html
            }
          }
      }

      nurseryClassFileDownload: allFile(filter: {absolutePath: {regex: "/books-list-class-nursery.pdf/i"}}) {
        edges {
          node {
            publicURL
          }
        }
      }

      lkgClassBooksList: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/books-list-class-lkg.md/i"}}) {
        edges {
          node {
            html
          }
        }
      }

      lkgClassFileDownload: allFile(filter: {absolutePath: {regex: "/books-list-class-lkg.pdf/i"}}) {
        edges {
          node {
            publicURL
          }
        }
      }

    ukgClassBooksList: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/books-list-class-ukg.md/i"}}) {
      edges {
        node {
          html
        }
      }
    }

    ukgClassFileDownload: allFile(filter: {absolutePath: {regex: "/books-list-class-ukg.pdf/i"}}) {
      edges {
        node {
          publicURL
        }
      }
    }

    iClassBooksList: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/books-list-class-i.md/i"}}) {
      edges {
        node {
          html
        }
      }
    }

    iClassFileDownload: allFile(filter: {absolutePath: {regex: "/books-list-class-i.pdf/i"}}) {
      edges {
        node {
          publicURL
        }
      }
    }

    iiClassBooksList: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/books-list-class-ii.md/i"}}) {
      edges {
        node {
          html
        }
      }
    }

    iiClassFileDownload: allFile(filter: {absolutePath: {regex: "/books-list-class-ii.pdf/i"}}) {
      edges {
        node {
          publicURL
        }
      }
    } 
    
    iiiClassBooksList: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/books-list-class-iii.md/i"}}) {
      edges {
        node {
          html
        }
      }
    }

    iiiClassFileDownload: allFile(filter: {absolutePath: {regex: "/books-list-class-iii.pdf/i"}}) {
      edges {
        node {
          publicURL
        }
      }
    }
    
    ivClassBooksList: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/books-list-class-iv/i"}}) {
      edges {
        node {
          html
        }
      }
    }

    ivClassFileDownload: allFile(filter: {absolutePath: {regex: "/books-list-class-iv.pdf/i"}}) {
      edges {
        node {
          publicURL
        }
      }
    }
    
    vClassBooksList: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/books-list-class-v.md/i"}}) {
      edges {
        node {
          html
        }
      }
    }

    vClassFileDownload: allFile(filter: {absolutePath: {regex: "/books-list-class-v.pdf/i"}}) {
      edges {
        node {
          publicURL
        }
      }
    }
    
    viClassBooksList: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/books-list-class-vi.md/i"}}) {
      edges {
        node {
          html
        }
      }
    }

    viClassFileDownload: allFile(filter: {absolutePath: {regex: "/books-list-class-vi.pdf/i"}}) {
      edges {
        node {
          publicURL
        }
      }
    }
    
    viiClassBooksList: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/books-list-class-vii.md/i"}}) {
      edges {
        node {
          html
        }
      }
    }

    viiClassFileDownload: allFile(filter: {absolutePath: {regex: "/books-list-class-vii.pdf/i"}}) {
      edges {
        node {
          publicURL
        }
      }
    }
    
    viiiClassBooksList: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/books-list-class-viii.md/i"}}) {
      edges {
        node {
          html
        }
      }
    }

    viiiClassFileDownload: allFile(filter: {absolutePath: {regex: "/books-list-class-viii.pdf/i"}}) {
      edges {
        node {
          publicURL
        }
      }
    }
    
    ixClassBooksList: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/books-list-class-ix.md/i"}}) {
      edges {
        node {
          html
        }
      }
    }

    ixClassFileDownload: allFile(filter: {absolutePath: {regex: "/books-list-class-ix.pdf/i"}}) {
      edges {
        node {
          publicURL
        }
      }
    }
    
    xClassBooksList: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/books-list-class-x.md/i"}}) {
      edges {
        node {
          html
        }
      }
    }

    xClassFileDownload: allFile(filter: {absolutePath: {regex: "/books-list-class-x.pdf/i"}}) {
      edges {
        node {
          publicURL
        }
      }
    }
    
    xiClassBooksList: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/books-list-class-xi.md/i"}}) {
      edges {
        node {
          html
        }
      }
    }

    xiClassFileDownload: allFile(filter: {absolutePath: {regex: "/books-list-class-xi.pdf/i"}}) {
      edges {
        node {
          publicURL
        }
      }
    }
    
    xiiClassBooksList: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/books-list-class-xii.md/i"}}) {
      edges {
        node {
          html
        }
      }
    }

    xiiClassFileDownload: allFile(filter: {absolutePath: {regex: "/books-list-class-xii.pdf/i"}}) {
      edges {
        node {
          publicURL
        }
      }
    }    

  }
  `)
    var classBooksList = name.toLowerCase()+"ClassBooksList"
    var classFileDownload = name.toLowerCase()+"ClassFileDownload";
    return <single-class-books-list>
    <div id={name.toLowerCase()} className={"container "+name.toLowerCase()}>
        <hr/>
        <h4>Class {name}</h4>
        <p>Pease click on the file icon to download the class {name} book list. <a href={data[classFileDownload].edges[0].node.publicURL} download><FaFile /></a> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
    </div> 
    </single-class-books-list>
}

export default SingleClassBooksList

